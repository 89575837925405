/*
-----------------------------
Header
-----------------------------
*/
.main-header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 50;
	width: 100%;
	padding: 50px 0;
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0.7) 1.02%,
		rgba(0, 0, 0, 0) 98.27%
	);
	// border: 1px solid red;

	will-change: transform;
	transition: transform 200ms ease-out;

	&.headroom--pinned {
		transform: translateY(0%);
	}
	&.headroom--unpinned {
		transform: translateY(calc(-100% - 50px));
	}

	&.dline-header {
		.contw {
			.stahl {
				visibility: visible;
				opacity: 1;
			}
		}
	}
	&.stahl-header {
		.contw {
			.dline {
				visibility: visible;
				opacity: 1;
			}
		}
	}

	.contw {
		display: flex;
		justify-content: flex-end;
		position: relative;
		.logo {
			display: block;
			width: 241px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(calc(-50% + 20px));
		}
		.side {
			display: flex;
			align-items: center;
			.cotiza {
				color: #fff;
				text-transform: uppercase;
				font-family: $lexend;
				letter-spacing: 0.12em;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
			.social {
				margin: 0 30px;
				a {
					text-decoration: none;
					margin: 0 10px;
				}
			}
			.btn-burger {
				button {
					display: block;
					width: 45px;
					height: 45px;
					border: none;
					background: $negro;
				}
			}
		}
		.stahl,
		.dline {
			visibility: hidden;
			opacity: 0;
			position: absolute;
			top: 80px;
			right: 15px;
			transition: all 0.3s linear;
			a {
				position: relative;
				z-index: 10;
			}
			&::before {
				content: "";
				display: block;
				width: 1000%;
				height: 100%;
				background: $negro;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 0;
			}
		}
		.dline {
			a {
				display: block;
				padding: 5px 0 5px 5px;
			}
		}
	}

	@media #{$break-lg} {
		.contw {
			.logo {
				width: 180px;
				left: 5px;
				transform: translateX(0) translateY(-50%);
			}
		}
	}

	@media #{$break-md} {
		.contw {
			.logo {
				width: 150px;
			}
			.side {
				.cotiza {
					display: none;
				}
			}
		}
	}

	@media #{$break-sm} {
		padding: 25px 0;
		.contw {
			.stahl,
			.dline,
			.side .social {
				display: none;
			}
			.logo {
				width: 110px;
			}
		}
	}
}

.menu-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 100vh;
	z-index: 99;
	overflow: hidden;

	visibility: hidden;

	.back {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #fff;
		z-index: 0;
	}
	.m-inner {
		display: flex;
		justify-content: center;
		align-items: stretch;
		position: relative;
		z-index: 10;
		overflow: hidden;
		.close-cont {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 30;
			padding-top: 50px;
			width: 100%;
			text-align: right;
			.close {
				background: none;
				border: none;
				padding: 0;
				margin: 0;
			}
		}
		.pic {
			width: 40%;
			min-height: 100vh;
			position: relative;
			img {
				@include posterbg;
			}
		}
		.side {
			width: 60%;
			min-height: 100vh;
			display: flex;
			justify-content: center;
			align-items: center;
			.inner {
				padding: 80px;
				width: 100%;
				.top {
					display: flex;
					flex-direction: row;
					align-items: center;
					.logo {
						margin-bottom: 50px;
					}
					.menu {
						width: 60%;
						ul {
							list-style-type: none;
							margin: 0;
							padding: 0;
							li {
								margin: 1.8em 0;
								a {
									color: $negro;
									font-size: 24px;
									letter-spacing: 1.63px;
									text-transform: uppercase;
									text-decoration: none;
									line-height: 1em;
									&.link {
										display: grid;

										.text {
											grid-area: 1/1;
											transition: opacity 0.4s
													cubic-bezier(
														0.445,
														0.05,
														0.55,
														0.95
													),
												transform 0.4s
													cubic-bezier(
														0.445,
														0.05,
														0.55,
														0.95
													);
											.word {
												white-space: nowrap;
											}
											.char {
												display: inline-block;
												transform-origin: 50% 50% 0.4em;
												transition: transform 0.5s
													cubic-bezier(0.5, 0, 0, 1);
												transition-delay: calc(
													0ms + var(--char-index) *
														25ms
												);
												backface-visibility: hidden;
												margin: 0 -0.02em;
											}
										}

										.text:nth-child(1) {
											font-family: $lexend;
										}
										.text:nth-child(2) {
											font-family: $druk;
											letter-spacing: 0.25em;
											opacity: 0;
											.char {
												transform: rotate3d(
													1,
													-0.5,
													0,
													90deg
												);
												// OR
												// transform: rotateX(90deg) rotateY(-45deg);
											}
										}

										&:hover {
											.text {
												transform: translateX(15px);
												&:nth-child(1) {
													opacity: 0;
													.char {
														transform: rotate3d(
															1,
															0.3,
															0,
															-90deg
														);
														// OR
														// transform: rotateX(-90deg) rotateY(-27deg);
													}
												}
												&:nth-child(2) {
													opacity: 1;
													.char {
														transform: rotate3d(
															0,
															0,
															0,
															90deg
														);
														// OR
														// transform: rotate(0);
													}
												}
											}
										}
									}
								}
							}
						}
					}
					.info {
						width: 40%;
						font-size: 12px;
						letter-spacing: normal;
						margin: 100px 0 20px 0;
						color: $negro;
						h6 {
							font-family: $bold;
							font-size: 16px;
							letter-spacing: $lts;
							margin: 0 0 1em;
						}
						p {
							font-size: 12px;
							letter-spacing: normal;
						}
						a {
							color: $negro;
						}
						.social {
							margin-top: 15px;
							a {
								text-decoration: none;
								margin-right: 10px;
							}
						}
					}
				}
			}
		}
	}
	@media #{$break-lg} {
		.m-inner {
			.pic {
				width: 25%;
			}
			.side {
				width: 75%;
			}
		}
	}
	@media #{$break-md} {
		.m-inner {
			.pic {
				display: none;
			}
			.side {
				width: 100%;
				.inner {
					padding: 0 30px;
				}
			}
		}
	}
	@media #{$break-sm} {
		.m-inner {
			.close-cont {
				padding-top: 25px;
			}
			.side {
				.inner {
					.top {
						.menu {
							width: 100%;
						}
						.info {
							display: none;
						}
					}
				}
			}
		}
	}
	@media #{$break-xs} {
		.m-inner {
			.side {
				.inner {
					.top {
						.menu {
							ul {
								li {
									a {
										font-size: 20px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/*
-----------------------------
Footer
-----------------------------
*/

.coverlay {
	position: fixed;
	width: 0;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 999;
	background-color: #fff;
}

.main-footer {
	background-color: $azul;
	padding-top: 100px;
	transition: background-color 1s linear;
	.top {
		.brand {
			.logo {
				margin-bottom: 50px;
			}
			.slogan {
				margin-bottom: 50px;
				margin-top: 100px;
				.stahl {
					display: none;
				}
			}
		}
	}
	.info {
		font-size: 12px;
		letter-spacing: normal;
		margin: 100px 0 20px 0;
		h6 {
			font-family: $bold;
			font-size: 16px;
			letter-spacing: $lts;
			margin: 0 0 1em;
		}
		p {
			font-size: 12px;
			letter-spacing: normal;
		}
		a {
			color: #fff;
		}
		.social {
			margin-top: 15px;
			a {
				text-decoration: none;
				margin-right: 10px;
			}
		}
	}
	.footer-nav {
		margin-bottom: 50px;
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			li {
				margin: 1em 0;
				a {
					color: #fff;
					font-size: 16px;
					letter-spacing: 1.63px;
					text-transform: uppercase;
					text-decoration: none;
					line-height: 1em;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
	.forma-basica {
		h6 {
			font-family: $bold;
			font-size: 16px;
			letter-spacing: $lts;
		}
		.form-control {
			background: $azul;
			font-size: 14px;
			border: none;
			border-bottom: 1px solid #fff;
			border-radius: 0;
			color: #fff;
		}
		.btn-solid {
			padding: 15px 30px;
		}
	}
	.bottom {
		background: darken($azul, 5%);
		padding: 40px 0;
		font-size: 12px;
		a {
			color: #fff;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
		.copy {
			text-align: right;
			text-transform: uppercase;
		}
	}
	&.stahl {
		background: $snegro;
		.top {
			.brand {
				.slogan {
					.stahl {
						display: block;
					}
					.dline {
						display: none;
					}
				}
			}
		}
		.info {
			h6 {
				font-family: $druk;
				font-size: 13px;
			}
		}
		.bottom {
			background: darken($snegro, 5%);
		}
		.forma-basica {
			.form-control {
				background: $snegro;
			}
		}
	}
	@media #{$break-lg} {
		.top {
			.brand {
				.slogan {
					margin-top: 0;
				}
			}
		}
	}
}
