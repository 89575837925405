/*
-----------------------------
Cotiza
-----------------------------
*/
.cotiza-cont {
	margin: 220px 0 100px 0;
	.forma-basica {
		.form-control {
			background: $negro;
			font-size: 14px;
			border: none;
			border-bottom: 1px solid #fff;
			border-radius: 0;
			color: #fff;
		}
		.btn-solid {
			padding: 15px 30px;
		}
	}
	@media #{$break-lg} {
		margin-top: 200px;
	}
	@media #{$break-sm} {
		margin-top: 100px;
	}
}

.gracias {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
}
