/*
-----------------------------
Nosotros
-----------------------------
*/
.nosotros {
	.staff {
		figure {
			figcaption {
				display: block;
				font-family: $bold;
				margin-top: 15px;
			}
		}
	}
	.organigrama {
		margin: 100px 0;
	}
}
