/*
-----------------------------
General
-----------------------------
*/

body {
	font-size: 16px;
	font-family: $regular;
	overflow-x: hidden;
	color: $fontcolor;
	background: $negro;
}

.is-invalid {
	border-color: crimson;
}

main {
	min-height: 100vh;
}

p,
blockquote {
	line-height: 145%;
	letter-spacing: $lts;
	strong {
		font-family: $bold;
	}
}

.contw {
	width: 100%;
	max-width: 1240px;
	margin: 0 auto;
	padding: 0 15px;
}

.heading-s1 {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $lexend;
		font-size: 40px;
		letter-spacing: 1.58px;
		text-transform: uppercase;

		@media #{$break-md} {
			font-size: 30px;
		}
		@media #{$break-sm} {
			font-size: 26px;
		}
	}
}

.heading-s2 {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $bold;
		font-size: 32px;
		letter-spacing: 0.67px;
	}
}

.heading-s3 {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: 24px;
		font-family: $druk;
		text-transform: uppercase;
		color: $rojo;
	}
}

.heading-s4 {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: 24px;
		font-family: $lexend;
		text-transform: uppercase;
		color: $rojo;
	}
}

.btn {
	font-family: $lexend;
	font-size: 14px;
	text-transform: uppercase;
	border-radius: 0;
	&.btn-solid {
		color: $negro;
		background: #fff;
		border: 1px solid $negro;
		padding: 15px 80px;
		&:hover {
			background: $negro;
			color: #fff;
			border-color: #fff;
		}
		&.negro {
			color: #fff;
			border-color: #fff;
			background: $negro;
			&:hover {
				background: #fff;
				color: $negro;
				border-color: $negro;
			}
		}
		&.btn-sm {
			padding: 15px 20px;
		}
	}
	&.btn-stahl-outline {
		font-family: $druk;
		font-size: 12px;
		border: 1px solid $negro;
		color: $negro;
		padding: 15px 30px;
		background: #fff;
		&:hover {
			color: #fff;
			background: $negro;
		}
	}
	@media #{$break-md} {
		&.btn-solid {
			color: $negro;
			background: #fff;
			border: 1px solid $negro;
			padding: 15px 30px;
		}
	}
}

.banner-interior {
	position: relative;
	overflow: hidden;
	.caption {
		width: 100%;
		min-height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		z-index: 10;
		.contw {
			position: relative;
		}
		h1 {
			font-family: $lexend;
			font-size: 40px;
			letter-spacing: 1.58px;
			text-align: center;
			text-transform: uppercase;
		}
	}
	.poster {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 5;
			background: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0.47) 0%,
				rgba(0, 0, 0, 0.47) 100%
			);
		}
		img {
			@include posterbg;
		}
	}
	&.banner-stahl {
		.linex-logo {
			position: absolute;
			top: 250px;
			left: 50px;
			z-index: 5;
		}
	}
	@media #{$break-lg} {
		&.banner-stahl {
			.linex-logo {
				top: 190px;
				left: 15px;
			}
		}
	}
	@media #{$break-md} {
		.caption {
			h1 {
				font-size: 32px;
			}
		}
		&.banner-dart {
			.logo {
				width: 100px;
				margin: auto;
			}
		}
	}
	@media #{$break-sm} {
		.caption {
			h1 {
				font-size: 26px;
			}
		}
	}
}

$bg-url: "../images/patron-sd.svg";
$bg-width: 73px;
$bg-height: 43px;

/* Animations */
@keyframes bg-scrolling-reverse {
	100% {
		background-position: $bg-width $bg-height;
	}
}

@keyframes bg-scrolling {
	0% {
		background-position: $bg-width $bg-height;
	}
}

.ds-circ {
	border-radius: 50%;
	background: url($bg-url) repeat 0 0;
	animation: bg-scrolling-reverse 5s infinite;
	animation-timing-function: linear;
}

// Loading spinner

.fs-loading {
	position: relative;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.lds-spinner {
	color: official;
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-spinner div {
	transform-origin: 40px 40px;
	animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
	content: " ";
	display: block;
	position: absolute;
	top: 3px;
	left: 37px;
	width: 6px;
	height: 18px;
	border-radius: 20%;
	background: #fff;
}
.lds-spinner div:nth-child(1) {
	transform: rotate(0deg);
	animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
	transform: rotate(30deg);
	animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
	transform: rotate(60deg);
	animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
	transform: rotate(90deg);
	animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
	transform: rotate(120deg);
	animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
	transform: rotate(150deg);
	animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
	transform: rotate(180deg);
	animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
	transform: rotate(210deg);
	animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
	transform: rotate(240deg);
	animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
	transform: rotate(270deg);
	animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
	transform: rotate(300deg);
	animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
	transform: rotate(330deg);
	animation-delay: 0s;
}
@keyframes lds-spinner {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
