/*
-----------------------------
Home
-----------------------------
*/

.main-banner {
	position: relative;
	overflow: hidden;
	.top,
	.bottom {
		content: "";
		display: block;
		width: 220px;
		height: 400px;
		background: url("../images/patron-sd.svg");
		background-repeat: space;
		position: absolute;
		z-index: 5;
		opacity: 0.3;
	}
	.top {
		top: 0;
		left: 5%;
	}
	.bottom {
		bottom: 0;
		right: 5%;
	}
	.contw {
		min-height: 100vh;
		position: relative;
		z-index: 10;
		display: flex;
		justify-content: center;
		align-items: center;
		.inner {
			width: 100%;
		}
		h1 {
			font-family: $lexend;
			font-size: 40px;
			letter-spacing: 1.58px;
			text-align: center;
			text-transform: uppercase;
		}
	}
	.poster {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		.top {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 5;
			background: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0.47) 0%,
				rgba(0, 0, 0, 0.47) 100%
			);
		}
		img {
			@include posterbg;
			opacity: 0;
		}
	}
	@media #{$break-md} {
		.top {
			display: none;
		}
		.bottom {
			left: 50%;
			transform: translateX(-50%);
			height: 300px;
		}
		.contw {
			h1 {
				font-size: 32px;
			}
		}
	}
	@media #{$break-sm} {
		.bottom {
			height: 200px;
		}
		.contw {
			h1 {
				font-size: 26px;
			}
		}
	}
}

.cta-dline {
	margin: 100px 0;
	.caption {
		position: relative;
		width: calc(100% + 200px);
		z-index: 10;
		.logo {
			margin-bottom: 100px;
		}
		p {
			font-family: $lexend;
			font-size: 20px;
		}
		.action {
			margin-top: 40px;
			text-align: right;
			.btn {
				white-space: nowrap;
			}
		}
	}
	figure {
		position: relative;
		z-index: 0;
		margin: 0;
	}
	&.art {
		.caption {
			p {
				padding-right: 200px;
			}
		}
	}
	&.stahl {
		figure {
			span {
				position: absolute;
				bottom: 0;
				right: 0;
			}
		}
	}

	@media #{$break-md} {
		.caption {
			width: 100%;
			text-align: center;
			.logo {
				margin: 0 auto;
				width: 150px;
			}
			p,
			.heading-s1 h2 {
				margin: 25px 0;
			}
			.action {
				margin-top: 0;
				text-align: left;
				text-align: center;
				button {
					white-space: nowrap;
					margin: auto;
				}
			}
		}
		&.art {
			.caption {
				p {
					padding-right: 0;
				}
			}
		}
		figure {
			margin: 35px 0 0 0;
		}
	}
	@media #{$break-sm} {
		&.stahl {
			figure {
				span {
					display: none;
				}
			}
		}
	}
}

.cta-stahl-dline {
	padding: 100px 0;
	.contw {
		display: flex;
		align-items: stretch;
		position: relative;
		.banner {
			position: relative;
			width: calc(50% + 250px);
			// border: 1px solid red;
			.inner {
				.logo {
					position: absolute;
					top: 30px;
					left: 0;
					z-index: 10;
				}
				.pic {
					position: relative;
					width: 580px;
					margin-left: auto;
					img {
						width: 580px;
						height: 580px;
						border-radius: 50%;
					}
					span {
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translateX(-50%) translateY(50%);
					}
				}
			}
		}
		.caption {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 20;
			width: calc(50% - 25px);
			.inner {
				background: $negro;
				padding: 20px;
			}
		}
	}
	@media #{$break-md} {
		.contw {
			flex-direction: column;
			.banner {
				width: 100%;
				.inner {
					.logo {
						top: 50%;
						transform: translateY(-50%);
						img {
							width: 300px;
							height: auto;
						}
					}
					.pic {
						width: 100%;
						margin: auto;
						padding-left: 150px;
						img {
							width: 100%;
							height: 300px;
							object-fit: cover;
							border-radius: 0;
						}
						span {
							display: none;
						}
					}
				}
			}
			.caption {
				position: relative;
				width: 100%;
				.inner {
					padding: 25px 15px 0;
				}
			}
		}
	}
	@media #{$break-sm} {
		.contw {
			.banner {
				.inner {
					.logo {
						img {
							width: 250px;
						}
					}
					.pic {
						padding-left: 100px;
					}
				}
			}
			.caption {
				.inner {
					padding: 25px 0 0;
				}
			}
		}
	}
}

.cta-creamos-espacios {
	margin: 100px 0;
	.caption {
		position: relative;
		z-index: 10;
		width: calc(100% + 350px);
	}

	@media #{$break-md} {
		.caption {
			width: 100%;
		}
	}
}

.cta-nosotros {
	margin: 100px 0;
	figure {
		margin: 0 0 15px;
		figcaption {
			display: block;
			font-family: $bold;
			margin-top: 15px;
		}
	}
}

.cta-showroom {
	position: relative;
	margin-top: 150px;
	.contw {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 680px;
		position: relative;
		z-index: 10;
		.ds-circ {
			width: 340px;
			height: 340px;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 40;
			transform: translateY(-100px);
		}
		.caption {
			text-align: center;
		}
	}
	.poster {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $negro;
			opacity: 0.5;
			z-index: 5;
		}
		img {
			@include posterbg;
		}
	}
	@media #{$break-lg} {
		.contw {
			.ds-circ {
				width: 280px;
				height: 280px;
			}
		}
	}
	@media #{$break-md} {
		.contw {
			.ds-circ {
				width: 200px;
				height: 200px;
				left: 15px;
			}
		}
	}
	@media #{$break-sm} {
		.contw {
			.ds-circ {
				width: 150px;
				height: 150px;
				transform: translateY(-70px);
			}
		}
	}
}
