/*
-----------------------------
Fonts
-----------------------------
*/
@font-face {
	font-family: "Druk Wide";
	src: url("../fonts/DrukWide-Medium.woff2") format("woff2"),
		url("../fonts/DrukWide-Medium.woff") format("woff"),
		url("../fonts/DrukWide-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Lexend Tera";
	src: url("../fonts/LexendTera-Regular.woff2") format("woff2"),
		url("../fonts/LexendTera-Regular.woff") format("woff"),
		url("../fonts/LexendTera-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Montserrat Light";
	src: url("../fonts/Montserrat-Light.woff2") format("woff2"),
		url("../fonts/Montserrat-Light.woff") format("woff"),
		url("../fonts/Montserrat-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Montserrat Bold";
	src: url("../fonts/Montserrat-Bold.woff2") format("woff2"),
		url("../fonts/Montserrat-Bold.woff") format("woff"),
		url("../fonts/Montserrat-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Montserrat Regular";
	src: url("../fonts/Montserrat-Regular.woff2") format("woff2"),
		url("../fonts/Montserrat-Regular.woff") format("woff"),
		url("../fonts/Montserrat-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

$regular: "Montserrat Regular", Helvetica, Arial, sans-serif;
$bold: "Montserrat Bold", Helvetica, Arial, sans-serif;
$light: "Montserrat Light", Helvetica, Arial, sans-serif;
$druk: "Druk Wide", Helvetica, Arial, sans-serif;
$lexend: "Lexend Tera", Helvetica, Arial, sans-serif;

$lts: "1.63px";

/*
-----------------------------
Colores
-----------------------------
*/
// dline
$fontcolor: #fff;
$rojo: #ff4313;
$negro: #000;
$azul: #153545;
// stahl
$gris: #f2f2f2;
$snegro: #1d1d1d;

/*
-----------------------------
Media queries
-----------------------------
*/
$break-xl: "screen and (max-width: 1199.98px)";
$break-lg: "screen and (max-width: 991.98px)";
$break-md: "screen and (max-width: 767.98px)";
$break-sm: "screen and (max-width: 575.98px)";
$break-xs: "screen and (max-width: 480px)";
$break-320: "screen and (max-width: 320px)";
$break-lap: "screen and (max-height : 900px)";

/*
-----------------------------
Mixins
-----------------------------
*/
@mixin btnTransition {
	transition: all 0.2s ease-out;
}

@mixin posterbg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: 0;
	user-select: none;
}
