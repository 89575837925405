/*
-----------------------------
Portafolio
-----------------------------
*/
.portafolio-cont {
	margin: 100px 0;
}

/*
-----------------------------
Portafolio Interior
-----------------------------
*/
.banner-proyecto {
	@extend .banner-interior;
	.ds-circ {
		width: 150px;
		height: 150px;
		margin: 0 auto 30px;
	}
	.caption {
		text-align: center;
		h1 {
			font-family: $bold;
			font-size: 26px;
			text-transform: inherit;
		}
		small {
			font-size: 26px;
			font-family: $regular;
		}
	}
}

.proyecto-interior-cont {
	margin: 100px 0 50px;
	.contw {
		padding: 0;
	}
	.grid {
		position: relative;
		width: 100%;
	}
	.sizer,
	.item {
		width: 33.3%;
		overflow: hidden;
		position: relative;
		.item-inner {
			position: relative;
			padding: 15px;
			.thumb {
				position: relative;
				z-index: 1;
				img {
					position: relative;
					width: 100%;
					height: 400px;
					object-fit: cover;
				}
			}
			.caption {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 10;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: rgba(0, 0, 0, 0.8);

				opacity: 0;
				transition: opacity 0.2s linear;

				.caption-inner {
					text-align: center;
					.titulo,
					.medida {
						font-family: $bold;
						font-size: 16px;
					}
					.etiqueta {
						font-size: 16px;
					}
					span {
						display: block;
					}
				}
				.caption-actions {
					margin-top: 15px;
					.btn {
						padding: 15px;
					}
				}
			}
			&:hover {
				.caption {
					opacity: 1;
				}
			}
		}
		&.intro {
			width: 33.3%;
			position: absolute;
			top: 0;
			left: 0;
			ul {
				list-style-type: none;
				margin: 0;
				padding: 0;
				li {
					line-height: 160%;
					margin-bottom: 0.5em;
					strong {
						font-family: $bold;
					}
				}
			}
		}
		&.horz {
			width: 66.6%;
		}
		&.vert {
			width: 33.3%;
			.item-inner {
				.thumb {
					img {
						height: 600px;
					}
				}
			}
		}
	}
	&.galeria-stahl {
		margin: 0;
		padding: 100px 0 50px;
		background: #fff;
		.item {
			&.intro {
				p {
					color: $negro;
				}
			}
		}
	}
	@media #{$break-md} {
		.sizer,
		.item {
			width: 50%;
			margin-bottom: 30px;
			&.intro {
				width: 100%;
			}
			&.horz,
			&.vert,
			&.cuadro {
				width: 50%;
				.item-inner {
					.thumb {
						img {
							width: 100%;
							height: auto;
						}
					}
					.caption {
						position: relative;
						opacity: 1;
						justify-content: flex-start;
						margin-top: 15px;
						.caption-inner {
							text-align: left;
						}
					}
				}
			}
		}
		&.portafolio-interior {
			.item {
				&.horz,
				&.vert,
				&.cuadro {
					width: 50%;
					.item-inner {
						.thumb {
							img {
								width: 100%;
								height: auto;
							}
						}
						.caption {
							position: absolute;
							top: 0;
							left: 0;
							align-items: flex-end;
							background: none;
							padding: 15px 0 30px 15px;
							opacity: 1;
							.caption-inner {
								.caption-actions {
									margin: 0;
								}
							}
						}
					}
				}
			}
		}
		&.galeria-stahl {
			.item {
				&.horz,
				&.vert,
				&.cuadro {
					.item-inner {
						.caption {
							color: $negro;
							background-color: #fff;
						}
					}
				}
			}
		}
	}
	@media #{$break-xs} {
		.sizer,
		.item {
			width: 100%;
			&.intro,
			&.horz,
			&.vert,
			&.cuadro {
				width: 100%;
			}
		}
		&.portafolio-interior {
			.item {
				width: 100%;
				&.intro,
				&.horz,
				&.vert,
				&.cuadro {
					width: 100%;
				}
			}
		}
	}
}

.pi-control {
	margin-bottom: 100px;
	.btn {
		color: #fff;
	}
	&.blanco {
		background: #fff;
		margin: 0;
		padding: 0 0 100px;
		.btn {
			color: $negro;
		}
	}
}

/*
-----------------------------
D-Line
-----------------------------
*/
.dline-seccion {
	margin: 100px 0;
}

.card-material {
	@extend .card-stahl-material;
	.caption {
		color: #fff;
	}
}

/*
-----------------------------
Galería
-----------------------------
*/
.galeria-cont {
	.proyecto-interior-cont {
		margin: 200px 0 100px 0;
	}
	@media #{$break-sm} {
		.proyecto-interior-cont {
			margin-top: 170px;
		}
	}
}
