/*
-----------------------------
Sathl
-----------------------------
*/
.stahl-intro {
	background: $gris;
	position: relative;
	overflow: hidden;
	.intro {
		position: relative;
		.banner {
			position: relative;
			.logo {
				position: absolute;
				top: 50%;
				left: 0;
				z-index: 10;
				background: #fff;
				padding: 40px;
				transform: translateX(-50%) translateY(-50%);
			}
		}
		.caption {
			position: relative;
			margin-top: 30px;
			p {
				color: $negro;
			}
			.simbolo {
				position: absolute;
				top: 0;
				right: 0;
				z-index: 10;
				transform: translateX(100%) translateY(-50%);
			}
		}
	}
	.blocks {
		margin: 100px 0;
	}
	@media #{$break-lg} {
		.intro {
			.banner {
				position: relative;
				.logo {
					top: 50%;
					width: 190px;
					padding: 20px;
					transform: translateX(-50%) translateY(-50%);
				}
				.poster {
					img {
						width: calc(100% + 15px);
						height: 400px;
						object-fit: cover;
					}
				}
			}
			.caption {
				padding-right: 100px;
				.simbolo {
					right: 80px;
				}
			}
		}
	}
	@media #{$break-md} {
		.intro {
			.banner {
				.logo {
					width: 160px;
					transform: translateX(-40px) translateY(-50%);
				}
			}
		}
	}
	@media #{$break-xs} {
		.intro {
			.caption {
				padding-right: 0;
				.simbolo {
					display: none;
				}
			}
		}
	}
}

.card-stahl-material {
	.thumb {
		margin: 0 40px 40px 40px;
		position: relative;
		&::before {
			content: "";
			display: block;
			width: 40%;
			height: 40%;
			border: 6px solid $rojo;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 10;
		}
		img {
			border-radius: 50%;
		}
	}
	.caption {
		color: $negro;
		h2 {
			text-align: center;
		}
		.lead {
			font-family: $druk;
			font-size: 14px;
			letter-spacing: $lts;
			padding: 0 40px;
			text-transform: uppercase;
			line-height: 135%;
			text-align: center;
		}
	}
}
